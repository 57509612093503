import { type SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId1 = `loading-icon-gradient-1-${id}`;
  const gradientId2 = `loading-icon-gradient-2-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C17.6376 27 19.189 26.6428 20.5829 26.0031C21.0848 25.7727 21.6785 25.9928 21.9088 26.4948C22.1392 26.9967 21.9191 27.5904 21.4171 27.8208C19.7668 28.5782 17.9313 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C21.2155 3 25.7115 6.07127 27.7821 10.4995C28.0161 10.9998 27.8001 11.595 27.2999 11.8289C26.7996 12.0629 26.2043 11.8469 25.9704 11.3466C24.2164 7.59547 20.4103 5 16 5Z"
        fill={`url(#${gradientId2})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 15C28.5523 15 29 15.4477 29 16C29 21.2481 25.8902 25.7678 21.4171 27.8208C19.7668 28.5782 17.9313 29 16 29C15.4477 29 15 28.5523 15 28C15 27.4477 15.4477 27 16 27C17.6376 27 19.189 26.6428 20.5829 26.0031C24.3719 24.264 27 20.4379 27 16C27 15.4477 27.4477 15 28 15Z"
        fill={`url(#${gradientId1})`}
      />
      <defs>
        <linearGradient
          id={gradientId2}
          x1="28"
          y1="11.8462"
          x2="28.6017"
          y2="16.1731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B1423" />
          <stop offset="0.744796" stopColor="#9A455A" />
        </linearGradient>
        <linearGradient
          id={gradientId1}
          x1="21"
          y1="30.5"
          x2="28"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.297593" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const Loading = memo(SvgComponent);
