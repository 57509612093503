import type { FC, ReactNode } from "react";
import clsx from "clsx";

export interface InfoBoxProps {
  text: string;
  name: string;
  className?: string;
  icon?: ReactNode;
}

export const InfoBox: FC<InfoBoxProps> = ({ text, className, icon, name }) => (
  <div id={name} className={clsx("bg-grey-light flex items-center p-6", className)}>
    {icon}
    <p className="text-forms-md text-primary-black ml-4 font-normal">{text}</p>
  </div>
);
