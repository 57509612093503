import { type SVGProps, memo } from "react";

const SvgComponent = ({
  width = 32,
  color = "currentColor",
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 20.5C16.492 20.5 17.5 21.508 17.5 22.75C17.5 23.992 16.492 25 15.25 25C14.008 25 13 23.992 13 22.75C13 21.508 14.008 20.5 15.25 20.5ZM15.25 13.75C16.492 13.75 17.5 14.758 17.5 16C17.5 17.242 16.492 18.25 15.25 18.25C14.008 18.25 13 17.242 13 16C13 14.758 14.008 13.75 15.25 13.75ZM15.25 7C16.492 7 17.5 8.008 17.5 9.25C17.5 10.492 16.492 11.5 15.25 11.5C14.008 11.5 13 10.492 13 9.25C13 8.008 14.008 7 15.25 7Z"
        fill={color}
      />
    </svg>
  );
};
export const More = memo(SvgComponent);
