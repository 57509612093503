import { type SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId1 = `loading-icon-gradient-1-${id}`;
  const gradientId2 = `loading-icon-gradient-2-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="UI/Loading">
        <path
          id="Ellipse 118 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3.12512C6.20304 3.12512 3.125 6.20316 3.125 10.0001C3.125 13.7971 6.20304 16.8751 10 16.8751C11.0235 16.8751 11.9931 16.6519 12.8643 16.252C13.178 16.1081 13.549 16.2456 13.693 16.5594C13.837 16.8731 13.6994 17.2441 13.3857 17.3881C12.3543 17.8615 11.2071 18.1251 10 18.1251C5.51269 18.1251 1.875 14.4874 1.875 10.0001C1.875 5.51281 5.51269 1.87512 10 1.87512C13.2597 1.87512 16.0697 3.79467 17.3638 6.56231C17.51 6.875 17.3751 7.247 17.0624 7.39321C16.7497 7.53942 16.3777 7.40446 16.2315 7.09178C15.1353 4.74729 12.7564 3.12512 10 3.12512Z"
          fill={`url(#${gradientId1})`}
        />
        <path
          id="Ellipse 121 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 9.37512C17.8452 9.37512 18.125 9.65494 18.125 10.0001C18.125 13.2802 16.1814 16.105 13.3857 17.3881C12.3543 17.8615 11.2071 18.1251 10 18.1251C9.65482 18.1251 9.375 17.8453 9.375 17.5001C9.375 17.1549 9.65482 16.8751 10 16.8751C11.0235 16.8751 11.9931 16.6519 12.8643 16.252C15.2324 15.1651 16.875 12.7738 16.875 10.0001C16.875 9.65494 17.1548 9.37512 17.5 9.37512Z"
          fill={`url(#${gradientId2})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId1}
          x1="15.4167"
          y1="5.00012"
          x2="6.3237"
          y2="14.8849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="0.744796" stopColor="#9A455A" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1="13.125"
          y1="19.0626"
          x2="17.5"
          y2="11.8751"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.297593" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const LoadingDark = memo(SvgComponent);
