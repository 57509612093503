import clsx from "clsx";

interface Props {
  children?: React.ReactNode;
  className?: string;
  variant?: CardVariant;
  fullHeight?: boolean;
  rounded?: boolean;
}

type CardVariant = "white" | "grey";

const CardTitle: React.FC<{
  children: React.ReactNode;
  overrideMargin?: string;
  className?: string;
}> = ({ children, overrideMargin, className }) => (
  <h3
    className={clsx(
      "text-menu-sm",
      "font-bold",
      "text-secondary-aubergine",
      "tablet:text-menu",
      overrideMargin ? overrideMargin : "pb-6",
      className
    )}
  >
    {children}
  </h3>
);

const CardIntro: React.FC<{
  children: React.ReactNode;
  overrideMarginPadding?: string;
}> = ({ children, overrideMarginPadding }) => (
  <div className={`text-p-sm tablet:text-p ${overrideMarginPadding ?? "mb-3 pb-2"} p-sm`}>
    {children}
  </div>
);

const Label: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={`${
      className ? `${className} ` : ""
    }text-label-xs tablet:text-label-sm font-semibold font-sans`}
  >
    {children}
  </div>
);

const TextItem: React.FC<{
  children: React.ReactNode;
  isLastItem?: boolean;
  className?: string;
}> = ({ children, isLastItem = false, className }) => (
  <div
    className={`${className ? `${className} ` : ""}${
      isLastItem ? "" : "mb-4 "
    }text-p-sm tablet:mb-6 font-serif`}
  >
    {children}
  </div>
);

const ChangeLink: React.FC<{
  title?: string;
  destination?: string;
  onClick?: (event?: React.MouseEvent) => void;
}> = ({ title = "Wijzigen", destination, onClick }) => (
  <a
    className="mx-auto mt-6 block text-center font-semibold underline hover:cursor-pointer"
    href={destination}
    onClick={onClick}
  >
    {title}
  </a>
);

export const Card: React.FC<Props> = ({
  children,
  className,
  variant,
  fullHeight = true,
  rounded = false,
}) => {
  return (
    <div
      className={clsx(
        "card",
        variant && styles[variant],
        !fullHeight && "h-fit",
        rounded && "rounded-2xl",
        className
      )}
    >
      {children}
    </div>
  );
};

// TODO: how to type this for an unknown number of classes
const styles: Record<CardVariant, string> = {
  white: "bg-white",
  grey: "bg-grey-light",
};

export { CardTitle, CardIntro, Label, TextItem, ChangeLink };
