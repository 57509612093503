import { memo, SVGProps } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.7797 22.7203L17.1469 16.0875C18.6094 14.3813 19.4578 12.1734 19.4578 9.75C19.4578 4.36406 15.0924 0 9.70782 0C4.32329 0 0 4.36547 0 9.75C0 15.1345 4.36501 19.5 9.70782 19.5C12.1303 19.5 14.3414 18.6112 16.0453 17.1492L22.6781 23.782C22.8656 23.925 23.0578 24 23.25 24C23.4422 24 23.6338 23.9268 23.7802 23.7803C24.075 23.4891 24.075 23.0109 23.7797 22.7203ZM9.75001 18C5.16095 18 1.50001 14.2969 1.50001 9.75C1.50001 5.20312 5.16095 1.5 9.75001 1.5C14.3391 1.5 18 5.16094 18 9.75C18 14.3391 14.2969 18 9.75001 18Z"
      fill="#0B1423"
    />
  </svg>
);

export const Search = memo(SvgComponent);
