import { useMemo } from "react";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
};

export const ELLIPSIS = "...";

export const usePagination = ({ currentPage, totalPages }: PaginationProps) => {
  return useMemo(() => {
    const pages: (number | string)[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      // Add first ellipsis if necessary
      if (currentPage > 3) {
        pages.push(ELLIPSIS);
      }

      // Calculate start and end of middle section
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);

      // Adjust the start and end of the middle section
      // based on how close currentPage is to the beginning or totalPages
      if (currentPage <= 3) {
        end = 4;
      } else if (currentPage >= totalPages - 2) {
        start = totalPages - 3;
      }

      // Add middle section
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      // Add second ellipsis if necessary
      if (currentPage < totalPages - 2) {
        pages.push(ELLIPSIS);
      }

      pages.push(totalPages);
    }

    return pages;
  }, [currentPage, totalPages]);
};
