import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24" {...props}>
    <path
      d="m8.5 14.25.618.786a1 1 0 0 0 .382-.786h-1Zm-2.034 2.613-.865-.502.865.502Zm13.068 0 .865-.502-.865.502ZM17.5 14.25h-1a1 1 0 0 0 .382.786l.618-.786Zm-8-4.5a3.5 3.5 0 0 1 3.5-3.5v-2a5.5 5.5 0 0 0-5.5 5.5h2Zm0 4.5v-4.5h-2v4.5h2Zm-2.168 3.114c.383-.662 1.114-1.799 1.786-2.328l-1.236-1.572c-1.013.797-1.912 2.261-2.28 2.897l1.73 1.003Zm-.082.33c0-.133.032-.244.082-.33l-1.73-1.003c-.244.42-.352.883-.352 1.334h2Zm.805.806a.805.805 0 0 1-.805-.805h-2A2.805 2.805 0 0 0 8.055 20.5v-2Zm.445 0h-.445v2H8.5v-2Zm9 0h-9v2h9v-2Zm.445 0H17.5v2h.445v-2Zm.805-.805c0 .445-.36.805-.805.805v2a2.805 2.805 0 0 0 2.805-2.805h-2Zm-.082-.331c.05.086.082.197.082.33h2c0-.45-.108-.913-.351-1.333l-1.73 1.003Zm-1.786-2.328c.672.529 1.403 1.666 1.786 2.328l1.73-1.003c-.368-.636-1.267-2.1-2.28-2.897l-1.236 1.572ZM16.5 9.75v4.5h2v-4.5h-2ZM13 6.25a3.5 3.5 0 0 1 3.5 3.5h2a5.5 5.5 0 0 0-5.5-5.5v2Z"
      fill={color}
    />
    <path d="M15.25 20.25a2.25 2.25 0 0 1-4.5 0" stroke={color} strokeWidth={2} />
    <path
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.25 3.25v1"
    />
    <path
      d="M5.826 9.002a1 1 0 1 0-1.318-1.504l1.318 1.504Zm-1.318 5.25a1 1 0 0 0 1.318-1.504l-1.318 1.504ZM5 10.875c0-.768.33-1.438.826-1.873L4.508 7.498A4.48 4.48 0 0 0 3 10.875h2Zm.826 1.873A2.48 2.48 0 0 1 5 10.875H3a4.48 4.48 0 0 0 1.508 3.377l1.318-1.504Z"
      fill={color}
    />
    <path
      d="M4.242 7.502a1 1 0 1 0-1.318-1.504l1.318 1.504Zm-1.318 8.25a1 1 0 0 0 1.318-1.504l-1.318 1.504Zm-.174-4.877c0-1.372.589-2.581 1.492-3.373L2.924 5.998C1.59 7.168.75 8.926.75 10.875h2Zm1.492 3.373c-.903-.792-1.492-2.002-1.492-3.373h-2c0 1.95.839 3.707 2.174 4.877l1.318-1.504ZM20.174 12.748a1 1 0 0 0 1.318 1.504l-1.318-1.504Zm1.318-5.25a1 1 0 1 0-1.318 1.504l1.318-1.504ZM21 10.875a2.48 2.48 0 0 1-.826 1.873l1.318 1.504A4.479 4.479 0 0 0 23 10.875h-2Zm-.826-1.873A2.48 2.48 0 0 1 21 10.875h2a4.479 4.479 0 0 0-1.508-3.377l-1.318 1.504Z"
      fill={color}
    />
    <path
      d="M21.758 14.248a1 1 0 1 0 1.318 1.504l-1.318-1.504Zm1.318-8.25a1 1 0 0 0-1.318 1.504l1.318-1.504Zm.174 4.877c0 1.371-.589 2.581-1.492 3.373l1.318 1.504c1.335-1.17 2.174-2.928 2.174-4.877h-2Zm-1.492-3.373c.903.792 1.492 2.001 1.492 3.373h2c0-1.95-.838-3.707-2.174-4.877l-1.318 1.504Z"
      fill={color}
    />
  </svg>
);

export const Alert = memo(SvgComponent);
