import type { MouseEvent } from "react";

type TableHeadingTab = {
  label: string;
  isActive: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  subLabel?: string;
};

export interface TableHeadingTabsProps {
  tabs: TableHeadingTab[];
}

export const TableHeadingTabs = ({ tabs }: TableHeadingTabsProps) => {
  const buttonClass =
    "whitespace-nowrap py-4 px-6 text-left grow border border-grey-asphalt disabled:bg-grey-light disabled:border-grey-light hover:underline hover:underline-offset-4 disabled:pointer-events-none font-sans";

  return (
    <>
      <div className="flex flex-row w-full">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={buttonClass}
            type="button"
            disabled={tab.isActive}
            onClick={tab.onClick}
          >
            <span className="flex font-bold text-h4-sm">{tab.label}</span>
            {tab.subLabel && <span>{tab.subLabel}</span>}
          </button>
        ))}
      </div>
    </>
  );
};
