"use client";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Close } from "../../icons";
import { twMerge } from "tailwind-merge";

interface Props {
  children: JSX.Element | string | JSX.Element[];
  isOpen: boolean;
  onClose?: () => void;
  hasWhiteCloseIcon?: boolean;
  className?: string;
  showCloseButton?: boolean;
}
const focusablesQuery =
  'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

export const Dialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  hasWhiteCloseIcon,
  className,
  showCloseButton = true,
}) => {
  const dialog = useRef<HTMLDialogElement>(null);
  const stopPropagation: MouseEventHandler = (e) => e.stopPropagation();
  const handleTabPresses = () => {
    const focusables = dialog.current?.querySelectorAll(focusablesQuery) as
      | NodeListOf<HTMLElement>
      | undefined;

    if (focusables && focusables.length > 0) {
      const firstTabbable = focusables[0];
      const lastTabbable = focusables[focusables.length - 1];
      const firstTab = function (event: globalThis.KeyboardEvent) {
        if (event.key === "Tab") {
          const alreadyCaptured = !!(
            document.activeElement &&
            Array.from(focusables).some((f: HTMLElement) => document.activeElement === f)
          );
          if (!alreadyCaptured) {
            event.preventDefault();
            firstTabbable.focus();
          }
          document.removeEventListener("keydown", firstTab);
        }
      };
      const tabListener = function (event: globalThis.KeyboardEvent) {
        if (!event.shiftKey && event.key === "Tab") {
          event.preventDefault();
          firstTabbable.focus();
        }
      };
      const shiftTabListener = function (event: globalThis.KeyboardEvent) {
        if (event.shiftKey && event.key === "Tab") {
          event.preventDefault();
          lastTabbable.focus();
        }
      };
      if (isOpen) {
        document.addEventListener("keydown", firstTab);
        lastTabbable.addEventListener("keydown", tabListener);
        firstTabbable.addEventListener("keydown", shiftTabListener);
      } else {
        lastTabbable.removeEventListener("keydown", tabListener);
        firstTabbable.removeEventListener("keydown", shiftTabListener);
        document.removeEventListener("keydown", firstTab);
      }
    }
  };
  const setBodyOverflow = () => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };
  const [isOpaque, setOpaque] = useState(false);
  const [isHidden, setHidden] = useState(true);
  useEffect(() => {
    handleTabPresses();
    setBodyOverflow();
    if (!isOpen) {
      setOpaque(false);
      setTimeout(() => {
        setHidden(true);
      }, 300);
    } else {
      setHidden(false);
      setTimeout(() => {
        setOpaque(true);
      }, 20); // hack to make sure fade in transition works
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen && onClose) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const transitionDurationClass = twMerge("transition duration-300");
  return (
    <div
      className={twMerge(
        `bg-primary-black/80 absolute inset-0 z-50`,
        transitionDurationClass,
        isOpaque ? "bg-primary-black/80" : "bg-primary-black/0",
        isHidden ? "hidden" : "block"
      )}
      onClick={onClose}
    >
      <dialog
        ref={dialog}
        role="dialog"
        onClick={stopPropagation}
        className={twMerge(
          "fixed top-1/2 left-1/2 z-50 max-h-full w-[calc(100%-2rem)] -translate-y-1/2 -translate-x-1/2 overflow-y-auto rounded-2xl bg-white tablet:bottom-auto tablet:w-[90%] desktop-s:w-[872px]",
          transitionDurationClass,
          isOpaque ? "opacity-100 -translate-y-1/2" : "opacity-0 -translate-y-2/3",
          className
        )}
        open={isOpen}
        autoFocus
      >
        <div className="tablet:top-10 tablet:right-10 absolute right-4 top-4 z-10 flex justify-end">
          {showCloseButton ? (
            <button
              aria-label="Close"
              onClick={onClose}
              className={twMerge(
                "tablet:size-6 relative flex size-5",
                hasWhiteCloseIcon && "drop-shadow-md"
              )}
            >
              <Close className={twMerge(hasWhiteCloseIcon && "invert")} />
            </button>
          ) : (
            <></>
          )}
        </div>
        {children}
      </dialog>
    </div>
  );
};
