import type { FC, ReactNode } from "react";
import clsx from "clsx";

type AlertBoxVariant = "green" | "yellow" | "red";

interface AlertBoxProps {
  children: ReactNode;
  className?: string;
  variant?: AlertBoxVariant;
}

const colorMap: Record<AlertBoxVariant, string> = {
  green: "border-signal-green",
  yellow: "border-signal-yellow",
  red: "border-signal-red",
};

export const AlertBox: FC<AlertBoxProps> = ({ children, className, variant = "yellow" }) => {
  return (
    <div
      className={clsx(
        colorMap[variant],
        "border border-l-8 rounded-lg",
        "px-6 py-3 tablet:py-5",
        "bg-white",
        "font-sans text-sm tablet:text-forms-md",
        className
      )}
    >
      {children}
    </div>
  );
};

export default AlertBox;
